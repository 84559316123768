import { useState } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import { BestPriceGuaranteedModal } from 'components/best-price-guaranteed-modal'

import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import s from './styles.module.scss'

const BestPriceGuaranteed = ({ variant = 'booking' }: { variant: 'pdp' | 'booking' | 'value-props' }) => {
  const { trackEvent } = useAppData()
  const { t } = useTranslation('common')

  const [bestPriceGuaranteeModal, setBestPriceModalVisibility] = useState(false)

  return (
    <>
      {variant === 'value-props' ? (
        <Button variant="link-tertiary" size="small" onClick={() => setBestPriceModalVisibility(true)}>
          {t('action.learnMore')}
        </Button>
      ) : (
        <div
          className={cn(s.bestPrice, s[`variant-${variant}`])}
          onClick={() => setBestPriceModalVisibility(true)}
        >
          <div className={s.label}>{t('action.bestPriceGuaranteed', { ns: 'common' })}</div>
          <Icon name="information-outline" size="mini" />
        </div>
      )}

      <BestPriceGuaranteedModal
        open={bestPriceGuaranteeModal}
        onClose={() => setBestPriceModalVisibility(false)}
        trackEvent={trackEvent.current}
      />
    </>
  )
}

export { BestPriceGuaranteed }
